const ROUTES = {
  VARIABLES: [
    { link: "variables/crear", name: "Crear Variable" },
    { link: "variables/portafolio", name: "Portafolio Ideal" },
    { link: "variables/caras", name: "Caras Categoría" },
  ],
  EXHIBIDORES: [
    { link: "exhibidores/crear", name: "Crear Exhibidor" },
    { link: "exhibidores/registar", name: "Registrar Exhibidor" },
    { link: "exhibidores/planograma", name: "Planograma" },
  ],
  AYUDA: [{ link: "ayuda", name: "Ayuda" }],
};
export const getCurrentPageName = (link) => {
  let name = "Moderna";
  if (link.startsWith("exhibidores")) {
    ROUTES.EXHIBIDORES.map((route) => {
      if (route.link === link) {
        name = route.name;
      }
    });
  } else {
    ROUTES.VARIABLES.map((route) => {
      if (route.link === link) {
        name = route.name;
      }
    });
  }
  return name;
};
export default ROUTES;
